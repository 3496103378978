import { v4 as uuid } from "uuid";
import { z } from "zod";

import { ContentBlockType, HorizontalAlignment, PostType, QuizType } from "~/enums";
import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheableMediaUrl";
import { sanitiseUnderscoreString } from "~/utilities/sanitise-underscore-string";

const commonContentBlockAttributesSchema = z.object({
	align: z.nativeEnum(HorizontalAlignment).nullable().optional(),
	height: z.union([z.number(), z.string()]).nullable().optional(),
	id: z.number().nullable().optional(),
	width: z.union([z.number(), z.string()]).nullable().optional(),
});

const storyShareContentSchema = z.object({
	featuredimagecontent: z.number().optional(),
	postexcerpt: z.string().optional(),
	posttitle: z.string().optional(),
});

const htmlAttributesSchema = commonContentBlockAttributesSchema.extend({
	html: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
});

const urlSchema = commonContentBlockAttributesSchema.extend({
	url: cacheableMediaUrlSchema().nullable().catch(null) as unknown as z.ZodNullable<
		ReturnType<typeof cacheableMediaUrlSchema>
	>,
});

const nonCacheableUrlSchema = commonContentBlockAttributesSchema.extend({
	url: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
});

const videoAttributesSchema = nonCacheableUrlSchema.extend({
	caption: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
	downloadUrl: cacheableMediaUrlSchema().nullable().catch(null) as unknown as z.ZodNullable<
		ReturnType<typeof cacheableMediaUrlSchema>
	>,
	format: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
	poster: cacheableMediaUrlSchema().nullable().optional().catch(null) as unknown as z.ZodNullable<z.ZodString>,
	subtitles: z
		.array(
			z.object({
				kind: z
					.union([z.literal("subtitles"), z.literal("captions")])
					.default("subtitles")
					.optional(),
				label: z.string().nullable(),
				src: cacheableMediaUrlSchema(),
				srcLang: z.string(),
			}),
		)
		.nullable()
		.optional(),
});

const fileAttributesSchema = urlSchema.extend({
	filename: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
	label: z.string().transform(sanitiseUnderscoreString).nullable(),
});

const imageAttributesSchema = urlSchema.extend({
	alt: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
	caption: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
});

const embedAttributesSchema = htmlAttributesSchema.extend({
	caption: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
});

const tableAttributesSchema = htmlAttributesSchema.extend({
	caption: z.string().nullable().catch(null) as unknown as z.ZodNullable<z.ZodString>,
	class_name: z.union([z.literal("is-style-stripes"), z.literal("is-style-regular"), z.string()]).nullable(),
	fixed_layout: z.boolean(),
});

const pdfAttributesSchema = z.object({
	id: z.number().nullable(),
	title: z.string().nullable(),
	url: cacheableMediaUrlSchema().nullable().catch(null) as unknown as z.ZodNullable<
		ReturnType<typeof cacheableMediaUrlSchema>
	>,
});

const nonRecursiveContentBlockSchema = z.discriminatedUnion("name", [
	z.object({ attributes: storyShareContentSchema, name: z.literal(ContentBlockType.CARD_CREATOR) }),
	z.object({
		attributes: embedAttributesSchema,
		name: z.literal(ContentBlockType.EMBED),
	}),
	z.object({ attributes: fileAttributesSchema, name: z.literal(ContentBlockType.FILE) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.HEADING) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.HTML) }),
	z.object({ attributes: nonCacheableUrlSchema, name: z.literal(ContentBlockType.IFRAME) }),
	z.object({ attributes: imageAttributesSchema, name: z.literal(ContentBlockType.IMAGE) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.LIST) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.PARAGRAPH) }),
	z.object({ attributes: pdfAttributesSchema, name: z.literal(ContentBlockType.PDF) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.QUOTE) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.SEPARATOR) }),
	z.object({ attributes: htmlAttributesSchema, name: z.literal(ContentBlockType.SPACER) }),
	z.object({ attributes: tableAttributesSchema, name: z.literal(ContentBlockType.TABLE) }),
	z.object({ attributes: videoAttributesSchema, name: z.literal(ContentBlockType.VIDEO) }),
]);

interface Column {
	attributes?: z.infer<typeof commonContentBlockAttributesSchema>;
	name: ContentBlockType.COLUMN;
	inner?: PreContentBlock[];
}

type PreContentBlock =
	| z.infer<typeof nonRecursiveContentBlockSchema>
	| Column
	| {
			attributes?: z.infer<typeof commonContentBlockAttributesSchema>;
			name: ContentBlockType.COLUMNS;
			inner?: Column[];
	  }
	| {
			attributes?: z.infer<typeof commonContentBlockAttributesSchema>;
			name: ContentBlockType.GROUP;
			inner?: PreContentBlock[];
	  };

export const contentBlockSchema: z.ZodType<PreContentBlock> = z.discriminatedUnion("name", [
	...nonRecursiveContentBlockSchema.options,
	z.object({
		attributes: commonContentBlockAttributesSchema.optional(),
		inner: z
			.array(
				z.object({
					attributes: commonContentBlockAttributesSchema.optional(),
					inner: z.array(z.lazy(() => contentBlockSchema)).optional(),
					name: z.literal(ContentBlockType.COLUMN),
				}),
			)
			.optional(),
		name: z.literal(ContentBlockType.COLUMNS),
	}),
	z.object({
		attributes: commonContentBlockAttributesSchema.optional(),
		inner: z.array(z.lazy(() => contentBlockSchema)).optional(),
		name: z.literal(ContentBlockType.COLUMN),
	}),
	z.object({
		attributes: commonContentBlockAttributesSchema.optional(),
		inner: z.array(z.lazy(() => contentBlockSchema)).optional(),
		name: z.literal(ContentBlockType.GROUP),
	}),
]);

export type ContentBlock = z.infer<typeof contentBlockSchema>;

const commonStageSchema = z.object({
	commentsAllowed: z.boolean().catch(false),
	commentsSupported: z.boolean().catch(false),
	content_locked: z.boolean().catch(false),
	id: z.number(),
	order: z.number(), // TODO: not sure this should be coming back on this response :?
	points: z.number().catch(0),
	title: z.string().transform(sanitiseUnderscoreString),
});

const articleStageSchema = commonStageSchema.extend({
	content: z.array(contentBlockSchema),
	type: z.literal(PostType.ARTICLE),
});

const audioVisualStageSchema = commonStageSchema.extend({
	content: z.array(contentBlockSchema),
	skippable: z.boolean().catch(false), // TODO: Remove catch once this gets sent through from BE
	type: z.literal(PostType.AUDIO_VISUAL),
	videoAttributes: videoAttributesSchema,
});

const pdfStageSchema = commonStageSchema.extend({
	content: z.array(contentBlockSchema),
	pdfAttributes: z.object({
		id: z.number(),
		title: z.string(),
		url: cacheableMediaUrlSchema(),
	}),
	skippable: z.boolean(),
	type: z.literal(PostType.PDF),
});

const quizStageSchema = commonStageSchema
	.extend({
		quizData: z.object({
			passMark: z.number(),
			questions: z.array(
				z.object({
					answers: z.array(
						z.object({
							correct: z.boolean().optional(),
							text: z.string(),
						}),
					),
					feedback: z
						.object({
							correctText: z.string().optional(),
							incorrectText: z.string().optional(),
						})
						.nullable(),
					id: z
						.string()
						.uuid()
						.catch(() => uuid()),
					randomise: z.boolean().optional(),
					text: z.string(),
					type: z.nativeEnum(QuizType),
				}),
			),
		}),
		type: z.literal(PostType.QUIZ),
	})
	// points cannot currently be assign to quiz stage
	.omit({ points: true });

export type QuizData = z.infer<typeof quizStageSchema>["quizData"];

export type Question = QuizData["questions"][number];

export type Answer = Question["answers"][number];

const scormStageSchema = commonStageSchema.extend({
	scorm: z.object({
		launchUrl: z.string(),
		proxyUrl: z.string(),
		title: z.string().optional(),
	}),
	type: z.literal(PostType.SCORM),
});

export type ScormStageSchema = z.infer<typeof scormStageSchema>;

export const stageSchema = z.discriminatedUnion("type", [
	articleStageSchema,
	audioVisualStageSchema,
	pdfStageSchema,
	quizStageSchema,
	scormStageSchema,
]);

export type StageResponse = z.infer<typeof stageSchema>;
