enum Stage {
	ARTICLE = "article-stage",
	AUDIO_VISUAL = "audio-visual-stage",
	PDF = "pdf-stage",
	QUIZ = "quiz-stage",
	SCORM = "scorm-stage",
}

export enum PostType {
	PATHWAY = "pathway",
	LEVEL = "level",
	MISSION = "mission",
	RESOURCE = "resource",
	RESOURCE_CATEGORY = "resource-category",
	ARTICLE = Stage.ARTICLE,
	AUDIO_VISUAL = Stage.AUDIO_VISUAL,
	PDF = Stage.PDF,
	QUIZ = Stage.QUIZ,
	SCORM = Stage.SCORM,
}

export enum PostGroup {
	PATHWAYS = "pathways",
	MISSIONS = "missions",
	RESOURCE_CATEGORIES = "resource-categories",
}

export enum ActivitySourceType {
	ARTICLE = "article",
	AUDIO_VISUAL = "video",
	PDF = "pdf",
	QUIZ = "quiz",
	SCORM = "scorm",
	BADGE = "badge",

	PATHWAY = PostType.PATHWAY,
	LEVEL = PostType.LEVEL,
	MISSION = PostType.MISSION,
}

export type StageType = `${Stage}`;

export enum ContentBlockType {
	CARD_CREATOR = "storyshare/card-creator",
	COLUMN = "core/column",
	COLUMNS = "core/columns",
	EMBED = "core/embed",
	FILE = "core/file",
	GROUP = "core/group",
	HEADING = "core/heading",
	HTML = "core/html",
	IFRAME = "core/iframe",
	IMAGE = "core/image",
	LIST = "core/list",
	PARAGRAPH = "core/paragraph",
	PDF = "storyshare/pdf",
	QUOTE = "core/quote",
	SEPARATOR = "core/separator",
	SPACER = "core/spacer",
	TABLE = "core/table",
	VIDEO = "core/video",
}

export enum QuizType {
	STANDARD = "standardQuestion",
	REORDERING = "reorderingQuestion",
}

export enum VideoMimeType {
	WEBM = "video/webm",
	OGG = "video/ogg",
	MP4 = "video/mp4",
	M3U8 = "application/x-mpegURL",
}

export enum FeaturedMediaType {
	WEBM = "video/webm",
	OGG = "video/ogg",
	MP4 = "video/mp4",
	MP3 = "audio/mp3",
	MPEG = "audio/mpeg",
	WAV = "audio/wav",
	OGA = "audio/ogg",
}

export enum HorizontalAlignment {
	CENTER = "center",
	LEFT = "left",
	RIGHT = "right",
}

export enum DirectStage {
	LEVEL = "direct-level-stage",
	MISSION = "direct-mission-stage",
	STAGE = "direct-stage",
}

export enum LeaderboardKey {
	MONTHLY = "monthly",
	OVERALL = "overall",
}

export enum ActivityStatus {
	IN_PROGRESS = "in-progress",
	COMPLETE = "complete",
	NOT_STARTED = "not-started",
}

export enum ActivityProgress {
	COMPLETE = "complete",
	START = "start",
}

export enum Legal {
	PRIVACY_POLICY = "privacy-policy",
	TERMS_AND_CONDITIONS = "terms-and-conditions",
}

export enum AuthType {
	INTERNAL = "internal",
	SSO = "sso",
}

export enum ServerErrorCode {
	INVALID_TENANT = "watt_invalid_tenant",
}

export enum FilterTypes {
	LEVEL = PostType.LEVEL,
	MISSION = PostType.MISSION,
	PATHWAY = PostType.PATHWAY,
	RESOURCE = PostType.RESOURCE,
	RESOURCE_CATEGORY = PostType.RESOURCE_CATEGORY,
	COMPLETED_CONTENT = "completed-content",
	STAGES = "stages",
}

export enum AnalyticsEventTypes {
	GENERIC = 1,
	NOTIFICATION = 2,
	APP = 3,
	AUTH = 4,
	SOCIAL = 5,
	MEDIA = 6,
	SEARCH = 7,
	NAVIGATION = 8,
}

export enum AnalyticsEventActions {
	ABANDON = "abandon",
	ANSWER = "answer",
	BOOKMARK = "bookmark",
	COMPLETE = "complete",
	ENDED = "ended",
	FAIL = "fail",
	LIKE = "like",
	PASS = "pass",
	REMOVE_BOOKMARK = "remove-bookmark",
	START = "start",
	PLAY = "play",
	PAUSE = "pause",
	UNLIKE = "unlike",
}
