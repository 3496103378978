import { type QueryClient } from "@tanstack/react-query";

import { LeaderboardKey } from "~/enums";

import { getAwardsBaseQueryOptions } from "../awards";
import { getInterestsBaseQueryOptions } from "../interests";
import { getAllLeadersBaseQueryOptions } from "../leaderboard/all-leaders";
import { getTopLeadersBaseQueryOptions } from "../leaderboard/top-leaders";
import { getActivitiesBaseQueryOptions } from "../profile/activities";
import { getBookmarksQueryOptions } from "../profile/bookmarks";
import { getCompletedLearningQueryOptions } from "../profile/completed-learning";
import { getResourceCategoriesBaseQueryOptions } from "../resources/categories";
import { getLatestResourcesBaseQueryOptions } from "../resources/latest-resources";

import { prefetchLearningData } from "./learning";

export const prefetchApiData = async (queryClient: QueryClient) => {
	await Promise.allSettled([
		queryClient.prefetchQuery(getActivitiesBaseQueryOptions()),
		queryClient.prefetchQuery(getAllLeadersBaseQueryOptions(LeaderboardKey.MONTHLY)),
		queryClient.prefetchQuery(getAllLeadersBaseQueryOptions(LeaderboardKey.OVERALL)),
		queryClient.prefetchQuery(getTopLeadersBaseQueryOptions(LeaderboardKey.MONTHLY)),
		queryClient.prefetchQuery(getTopLeadersBaseQueryOptions(LeaderboardKey.OVERALL)),
		queryClient.prefetchQuery(getAwardsBaseQueryOptions()),
		queryClient.prefetchQuery(getBookmarksQueryOptions()),
		queryClient.prefetchQuery(getInterestsBaseQueryOptions()),
		queryClient.prefetchQuery(getCompletedLearningQueryOptions()),
		queryClient.prefetchQuery(getResourceCategoriesBaseQueryOptions()),
		queryClient.prefetchQuery(getLatestResourcesBaseQueryOptions()),
		prefetchLearningData(queryClient),
	]);

	return Promise.resolve();
};
