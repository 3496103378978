import { z } from "zod";

const interestSchema = z.object({
	id: z.number(),
	title: z.string(),
});

const interestsSchema = z.array(interestSchema).catch([]);

type InterestsResponse = z.infer<typeof interestsSchema>;

export { interestsSchema, type InterestsResponse };
