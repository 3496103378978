import {
	useQuery,
	useSuspenseQuery,
	type UseQueryOptions,
	type UseSuspenseQueryOptions,
	type UseQueryResult,
	type UseSuspenseQueryResult,
	queryOptions,
} from "@tanstack/react-query";

import { type GetInterestsResponse, getInterests } from "./interests.api";
import { type InterestsResponse } from "./interests.schema";

const getQueryKey = () => ["interests"] as const;

const getBaseQueryOptions = <TData = GetInterestsResponse>() =>
	queryOptions<GetInterestsResponse, Error, TData>({
		queryFn: getInterests,
		queryKey: getQueryKey(),
	});

const useInterestsQuery = <TData = GetInterestsResponse>(
	options?: Pick<UseQueryOptions<GetInterestsResponse, Error, TData>, "select">,
): UseQueryResult<TData, Error> =>
	useQuery<GetInterestsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});

const useInterestsSuspenseQuery = <TData = GetInterestsResponse>(
	options?: Pick<UseSuspenseQueryOptions<GetInterestsResponse, Error, TData>, "select">,
): UseSuspenseQueryResult<TData, Error> => {
	return useSuspenseQuery<GetInterestsResponse, Error, TData>({
		...options,
		...getBaseQueryOptions(),
	});
};

export {
	useInterestsQuery,
	useInterestsSuspenseQuery,
	getBaseQueryOptions as getInterestsBaseQueryOptions,
	getQueryKey as getInterestsQueryKey,
	type InterestsResponse,
};
