import { z } from "zod";

const dynamicFeatureFlagsSchema = z.record(z.string(), z.boolean());

const featureFlagsSchema = z.object({
	enableComments: z.boolean(),
	enableGainsight: z.boolean(),
	enableInterests: z.boolean(),
	enableOfflineManagement: z.boolean(),
	enableSearch: z.boolean(),
	showPlaceholderUI: z.boolean().optional(),
});

const keysSchema = z.object({
	errorReporting: z.string(),
	gainsight: z.string(),
});
/**
 * We do not include the tenant in the dynamic config schema because it is not provided by the API, instead in is set from within the application code.
 **/
export const dynamicConfigSchema = z.object({
	endpointUrl: z.string().url().min(1),
	environment: z.string(),
	featureFlags: featureFlagsSchema.and(dynamicFeatureFlagsSchema),
	keys: keysSchema,
	remoteAssets: z.array(z.string()).catch([]),
});

export type DynamicGlobalConfig = z.infer<typeof dynamicConfigSchema>;

export type DynamicGlobalConfigFeatures = keyof z.infer<typeof featureFlagsSchema> | (string & {});

export class GlobalConfig {
	static configUrl = "/config.json";

	#config: null | DynamicGlobalConfig = null;

	public get() {
		if (!this.#config) {
			throw new Error("Global config has not been defined yet.");
		} else {
			return this.#config;
		}
	}

	public set(value: DynamicGlobalConfig) {
		if (!this.#config) {
			this.#config = value;
		} else {
			throw new Error("Global config has already been defined");
		}
	}
}

export const globalConfig = new GlobalConfig();

export const globalConfigUrl = GlobalConfig.configUrl;
