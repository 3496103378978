import { z } from "zod";

import { cacheableMediaUrlSchema } from "~/lib/schemas/cacheableMediaUrl";
import { sanitiseUnderscoreString } from "~/utilities/sanitise-underscore-string";

import { featuredMediaSchema } from "../shared.schema";

const baseSchema = z.object({
	content_locked: z.boolean().optional().catch(false),
	id: z.number(),
	points: z.number().catch(0),
	smi: z.number().catch(0),
	title: z.string().transform(sanitiseUnderscoreString),
});

export const baseLearningGroupSchema = z
	.object({
		description: z.string().nullable(),
		featuredMedia: featuredMediaSchema.nullable(),
	})
	.merge(baseSchema);

export const baseLearningGroupChild = z
	.object({
		childIds: z.array(z.number()).optional(),
		order: z.number(),
		thumbnailUrl: cacheableMediaUrlSchema().nullable().optional(),
	})
	.merge(baseSchema);

export type LearningGroupChild = z.infer<typeof baseLearningGroupChild>;

export const SmiSchema = z.number().catch(0);
const _OptionalSmiSchema = SmiSchema.optional();

export type OptionalSMI = z.infer<typeof _OptionalSmiSchema>;
export type OptionalSMIProp = { smi?: OptionalSMI };
