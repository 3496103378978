import { type QueryClient } from "@tanstack/react-query";
import { Outlet, createRootRouteWithContext, lazyRouteComponent } from "@tanstack/react-router";

export interface RouterContext {
	auth: {
		isAuthenticated: () => boolean;
	};
	analyticsTitle?: () => string;
	breadcrumbs?: {
		crumb?: () => string | undefined;
	};
	legal: {
		acceptingPrivacyPolicy: boolean;
		acceptingTermsConditions: boolean;
	};
	queryClient: QueryClient;
}

export const rootRoute = createRootRouteWithContext<RouterContext>()({
	component: () => <Outlet />,
	notFoundComponent: lazyRouteComponent(
		() => import("./components/NotFounds/RootRouteNotFound"),
		"RootRouteNotFound",
	),
});
